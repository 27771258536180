export default class FVendor {
  constructor(
      id,
      fdivisionBean,
      vcode,
      vname,
      avatarImage,
      statusActive,
      branch,
      childOf,
      address1,
      address2,
      district1,
      city1,
      state1,
      phone,
      countryCode,
      zipCode,
      email,
      joinDate,
      lastTrans,
      noRekening,
      currency,
      disc2Margin,
      disc1PlusMargin,
      pkp,
      namaPrshFakturPajak,
      namaPengusahaKenaPajak,
      npwp,
      tanggalPengukuhanPkp,
      top,
      wsport,
      disc1RegManual,
      discPlusRegManual,
      created,
      modified,
      modifiedBy,
      sourceId
  ) {
    this.id = id;
    this.fdivisionBean = fdivisionBean;
    this.vcode = vcode;
    this.vname = vname;
    this.avatarImage = avatarImage;
    this.statusActive = statusActive;
    this.branch = branch;
    this.childOf = childOf;
    this.address1 = address1;
    this.address2 = address2;
    this.district1 = district1;
    this.city1 = city1;
    this.state1 = state1;
    this.phone = phone;
    this.countryCode = countryCode;
    this.zipCode = zipCode;
    this.email = email;
    this.joinDate = joinDate;
    this.lastTrans = lastTrans;
    this.noRekening = noRekening;
    this.currency = currency;
    this.disc2Margin = disc2Margin;
    this.disc1PlusMargin = disc1PlusMargin;
    this.pkp = pkp;
    this.namaPrshFakturPajak = namaPrshFakturPajak;
    this.namaPengusahaKenaPajak = namaPengusahaKenaPajak;
    this.npwp = npwp;
    this.tanggalPengukuhanPkp = tanggalPengukuhanPkp;
    this.top = top;
    this.wsport = wsport;
    this.disc1RegManual = disc1RegManual;
    this.discPlusRegManual = discPlusRegManual;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
    this.sourceId = sourceId;
  }
}
