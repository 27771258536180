<template>

  <v-container>

    <v-row>

      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-container class="text-center">
          <v-row
              class="fill-height"
              align="center"
              justify="center"
          >
            <template>
              <v-col
                  cols="12"
                  md="6"
                  sm="6"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                      rounded
                      :elevation="hover ? 10 : 1"
                      :class="[{ 'on-hover': hover }, hover?'card-hover-opacity':'card-not-hover-opacity']"
                      class="rounded-0"
                  >
                    <v-img
                        :lazy-src="lookupImageUrlLazy(itemModified)"
                        :src="lookupImageUrl(itemModified)"
                        class="rounded-0"
                    >
                      <v-card-title class="text-h6 white--text fill-height">

                        <v-row
                            class="fill-height flex-column"
                            justify="space-between"
                        >
                          <v-spacer/>
                          <div class="align-self-center">
                            <v-btn
                                :class="{ 'show-btns': hover }"
                                :color="transparent"
                                icon
                                large
                                dark
                                outlined
                                @click="showDialogUpload"
                            >
                              <v-icon
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  large
                              >
                                mdi-upload
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-row>

                      </v-card-title>
                    </v-img>

                  </v-card>
                </v-hover>
              </v-col>
            </template>
          </v-row>
        </v-container>

      </v-col>

      <v-col
          cols="12"
          sm="8"
          md="8"
      >

        <v-row>
          <v-col
              cols="12"
              sm="6"
              md="4"
          >
            <v-text-field
                v-model="itemModified.vcode"
                :rules="rulesLenght"
                label="Kode"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="2"
              md="2"
          >
            <v-switch
                v-model="itemModified.statusActive"
                :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
                x-small
                class="pa-3"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <v-text-field
                v-model="itemModified.vname"
                label="Nama Supplier"
                :rules="rulesLenght"
            ></v-text-field>

          </v-col>
        </v-row>

      </v-col>

    </v-row>



    <v-row>
      <v-col
          cols="12"
          sm="5"
          md="5"
      >
        <v-autocomplete
            v-model="itemModified.fdivisionBean"
            :items="itemsFDivision"
            :rules="rulesNotEmtpy"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Division/Branch"
            :hint="`Division = Branch/Distributor : ${lookupFDivision(itemModified.fdivisionBean)}`"
            persistent-hint
        ></v-autocomplete>

      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          sm="6"
          md="6"
      >
        <v-text-field
            v-model="itemModified.address1"
            label="Alamat"
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="3"
          md="3"
      >
        <v-text-field
            v-model="itemModified.district1"
            label="Kecamatan"
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="3"
          md="3"
      >
        <v-text-field
            v-model="itemModified.city1"
            label="Kota"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-text-field
            v-model="itemModified.phone"
            label="Phone"
            type="number"
            prepend-inner-icon="mdi-phone"
            :prefix="`+${itemModified.countryCode}`"
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-text-field
            v-model="itemModified.email"
            label="Email"
            prepend-inner-icon="mdi-email"
        ></v-text-field>
      </v-col>
    </v-row>

    <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
    >
    </UploadImageDialog>
    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>

</template>

<script>
import {format, parseISO} from "date-fns";
import FVendor from "../../../models/f-vendor";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../../components/utils/UploadImageDialog";
import FVendorService from "../../../services/apiservices/f-vendor-service"
import FormMode from "../../../models/form-mode";

export default {
  components:{
    UploadImageDialog
  },
  props:{
    itemModified: new FVendor(),
    itemsFDivision: [],

  },
  data() {
    return{
      snackBarMesage: '',
      snackbar: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,


      selectedItemIndex: -1,
      itemDefault: '',
      selectFDivision: {id: 0, kode1: '', description: '' },

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  methods:{
    showDialogUpload(){
      if (this.itemModified.vcode !==undefined &&
          this.itemModified.vname !==undefined &&
        this.itemModified.fdivisionBean !==undefined){

        if (this.itemModified.id===0){
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly()
        }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Pcode, Pname dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },
    completeUploadSuccess: function (val){

      // console.log(val)
      //Hapus Dulu yang lama
      // console.log(this.itemModified.avatarImage)
      if (this.itemModified.avatarImage !==undefined && this.itemModified.avatarImage !== '' ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
            response => {
              console.log(response.data)
            },
            error => {
              console.log(error.response)
            }
        )
      }

      if (val.fileName !==""){
        this.$refs.refUploadDialog.closeDialog()
        this.itemModified.avatarImage = val.fileName
        this.saveUpdateOnly()
      }

    },
    saveCreateOnly(){
      FVendorService.createFVendor(this.itemModified).then(
          response =>{
            /**
             * dipaksa Save dan Update Dahulu
             */
            this.initializeEditMode(response.data)
            this.formMode = FormMode.EDIT_FORM
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      FVendorService.updateFVendor(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return require('@/assets/images/no_image_available.jpeg')
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  }

}

</script>


<style scoped>
.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>
